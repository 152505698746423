const PERFORMANCE_CLIENTE = {
  options: 'PerfomanceClienteFilterOptions',
  details: 'PerfomanceClienteFilterDetails',
}
const SENHA_ATENDIMENTO = {
  options: 'SenhaAtendimentoFilterOptions',
  details: 'SenhaAtendimentoFilterDetails',
  storage: 'senha_atendimento',
  headers: 'headerSenhaAtendimento',
}
const LOGGING = {
  options: 'LoggingFilterOptions',
  details: 'LoggingFilterDetails',
  storage: 'logging',
  headers: 'headerLogging',
}

const PEDIDOS = {
  options: 'PedidosOptions',
  details: 'PedidosDetails',
  headers: 'PedidosHeaders',
}

const saveHeaders = (LOCAL_STORAGE_ID, headers) => {
  if (!headers) return
  localStorage.setItem(LOCAL_STORAGE_ID.headers, JSON.stringify(headers))
}

const setIndicadores = (LOCAL_STORAGE_ID, headers) => {
  const headersSaved = localStorage.getItem(LOCAL_STORAGE_ID.headers)

  const headersSavedParsed = JSON.parse(headersSaved)
  if (
    headersSaved &&
    Array.isArray(headersSavedParsed) &&
    headersSavedParsed.length > 0
  ) {
    const headersMap = headers.map((item) => item.value)
    // Pega apenas os itens que existem no headers (itens antigos são desconsiderados)
    const _headersSavedParsed = headersSavedParsed.filter((item) =>
      headersMap.includes(item.value)
    )
    const _headersSavedParsedMap = _headersSavedParsed.map((item) => item.value)
    const headers_saved_map_habilited = _headersSavedParsed
      .filter((item) => item.habilited)
      .map((item) => item.value)
    const headers_saved_map_not_habilited = _headersSavedParsed
      .filter((item) => !item.habilited)
      .map((item) => item.value)
    // headersSavedParsed
    const newItems = headers
      .map((item, index) => {
        return [index, item]
      })
      .filter((item) => !_headersSavedParsedMap.includes(item[1].value))
    headers = _headersSavedParsed.map((item) => {
      let habilited = false
      if (headers_saved_map_habilited.includes(item.value)) {
        habilited = true
      } else {
        if (headers_saved_map_not_habilited.includes(item.value)) {
          habilited = false
        } else {
          // Para os novos
          habilited = item.habilited
        }
      }
      return {
        ...item,
        habilited,
      }
    })
    // Adiciona os novos itens na ordem que eles deveriam estar
    newItems.forEach((element) => {
      const [index, value] = element
      headers.splice(index, 0, value)
    })
    // Object.assign(headers, _headers)
  }
  saveHeaders(LOCAL_STORAGE_ID, headers)
  return headers
}

export {
  PEDIDOS,
  PERFORMANCE_CLIENTE,
  SENHA_ATENDIMENTO,
  LOGGING,
  setIndicadores,
  saveHeaders,
}
